import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

function encodeStr(str) {
  //Encode a string to URI and parse it to lowercase
  return encodeURI(`${str}`);
}
async function parseDirectionToCoordinates(
  entidad = '',
  municipio = ' ',
  calle = ' ',
  type
) {
  //A function who takes arguments like a calle, direcction, municio
  //and past it as a paramether to mapbox api.

  let params = '';
  let queryType = '';

  switch (type) {
    case 'calle':
      params = `${calle},${municipio},${entidad}`;
      queryType = 'address';
      break;
    case 'municipio':
      params = `${entidad} ${municipio}`;
      queryType = '';
      break;
    case 'estado':
      params = `${entidad}`;
      queryType = 'place';
      break;
    default:
      params = `${municipio}`;
      break;
  }

  const url_query = `${
    process.env.REACT_APP_MAPBOX
  }/geocoding/v5/mapbox.places/${encodeStr(
    params
  )}.json?country=mx&proximity=ip&types=${queryType}&language=es&access_token=${
    process.env.REACT_APP_MAPBOX_API_KEY
  }`;

  let response = await fetch(url_query);

  let sugestData = await response.json();

  const coordinates = sugestData.features[0].geometry.coordinates;
  const placeName = sugestData.features[0].place_name;
  const center = sugestData.features[0].center;

  return {
    type: 'Feature',
    center: center,
    geometry: {
      type: 'Point',
      coordinates: coordinates,
    },
    properties: {
      description: placeName,
    },
  };
}
const createMarker = (
  markerData,
  mapToAdd,
  isCenter = false,
  markerType,
  med
) => {
  //Function to create a marker and add it to map
  const coordinates = markerData.geometry.coordinates;
  const description = markerData.properties.description;
  const center = markerData.center;
  const el = document.createElement('div');
  el.className = `marker marker-${markerType}`;

  const marker = new mapboxgl.Marker(el)
    .setLngLat(coordinates)
    .setPopup(
      new mapboxgl.Popup({ offset: 25 }) // add popups
        .setHTML(`<div>
        <h3 style="color:#1c2afc;font-weight:bold;font-size:14px;">${med}</h3>
        <p style="color:black;font-size:12px;">${description}</p>
        </div>`)
    )
    .addTo(mapToAdd.current);

  if (isCenter) {
    mapToAdd.current.on('load', async () => {
      mapToAdd.current.flyTo({
        center: center,
      });
    });
  }

  return marker;
};
const createMarkerC = (
  markerData,
  mapToAdd,
  isCenter = false,
  Clave
) => {
  //Function to create a marker and add it to map
  const coordinates = markerData.geometry.coordinates;
  const description = markerData.properties.description;
  const center = markerData.center;
  const el = document.createElement('div');
  el.className = `marker marker-clinic`;

  const marker = new mapboxgl.Marker(el)
    .setLngLat(coordinates)
    .setPopup(
      new mapboxgl.Popup({ offset: 25 }) // add popups
        .setHTML(`<div>
        <h3 style="color:#1c2afc;font-weight:bold;font-size:14px;">Clinica #${Clave}</h3>
        <p style="color:black;font-size:12px;">${description}</p>
        </div>`)
    )
    .addTo(mapToAdd.current);

  if (isCenter) {
    mapToAdd.current.on('load', async () => {
      mapToAdd.current.flyTo({
        center: center,
      });
    });
  }

  return marker;
};
export { parseDirectionToCoordinates, createMarker,createMarkerC };
