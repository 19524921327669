/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import './Map.css';

import { parseDirectionToCoordinates, createMarker ,createMarkerC} from '../../utils/mapbox';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;
const Map = ({ entidad, municipio, dataToDisplay = [] ,vtipo}) => {
  const [items, setItems] = useState(dataToDisplay);
  const [markers, setMarkers] = useState([]);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng] = useState(-99.12766);
  const [lat] = useState(19.42847);
  const [zoom] = useState(10);

  useEffect(() => {
    setItems(dataToDisplay);
  }, [dataToDisplay.length]);

  useEffect(() => {
    //Sets the maps defaultstate
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [lng, lat],
      zoom: zoom,
    });
  }, []);

  const getCoordinates = async (entidad, municipio, calle, type) => {
    return await parseDirectionToCoordinates(entidad, municipio, calle, type);
  };

  const displayMarkers = async (dataToDisplay = [], Genero) => {
  
    markers.forEach((item) => item.remove());
 
    if(!vtipo){
    const promises = dataToDisplay.map(
      async ({
        Entidad,
        Municipio,
        Calle,
        Medico: { Nombre, ApellidoMaterno, ApellidoPaterno, Genero },
      }) => {
        const result = await getCoordinates(Entidad, Municipio, Calle, 'calle');
        const med = `${Nombre} ${ApellidoPaterno} ${ApellidoMaterno}`;

        return createMarker(result, map, false, Genero, med,vtipo);
      }
    );
    const m = await Promise.all(promises);
    setMarkers(m);
    }
    else{
      const promiseses = dataToDisplay.map(
        async ({
          Entidad,
          Municipio,
          Calle,
          Clave
        }) => {
          const result = await getCoordinates(Entidad, Municipio, Calle, 'calle');
         
  
          return createMarkerC(result, map, false,Clave);
        }
      );
      const mn = await Promise.all(promiseses);
      setMarkers(mn);
    }
  };

  useEffect(() => {
    //Function to update the lat and long

    if (!map.current) return;
    // Waits for the map to initialise
    let results;

    if (municipio) {
      results = getCoordinates(entidad, municipio, '', 'municipio');
    } else {
      results = getCoordinates(entidad, '', '', 'estado');
    }

    results.then((marker) => {
      map.current.flyTo({
        center: marker.center,
        essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        zoom: zoom,
      });
      displayMarkers(items);
    });
  }, [entidad, municipio, items.length]);

  return <div ref={mapContainer} className='map-container' />;
};

export default Map;
