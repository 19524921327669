/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

import Lupa from '../../assets/no_doctors.svg';
import Clinica from './Clinica/Clinica';
import redGineApi from '../../SDK/RedGine.api';
import DropDown from '../../Componentes/Dropdown/DropdownComponent';
import Spinner from '../../Componentes/Spinner/Spinner';
import Map from '../../Componentes/Map/Map';

import { useQuery } from '../../utils/query';

import './Clinicas.css';

function Contacto({
  setHasBackground,
  clinics,
  municipio,
  entidad,
  municipios,
  Buscar,
  iconPin,
  Entidades,
  getMunicipio,
  setMunicipio,
  setClinics,
  setFetch,
  fetch,
  busqueda,
  setBusqueda,vtipo
}) {
  setHasBackground(true);

  const [currentClinicas, setCurrentClinicas] = useState(false);
  const [ver, setver] = useState(false);
  useEffect(() => {
    if (clinics) {
    
      setver(busqueda)

      if (ver) {
        var medic = [clinics];
        var medicd = [clinics];
        var clin = [];
        var jmedico = [];

        medic.map((inmedico) => {
          inmedico.map((dato) => {
            let me = medicd.map(x => x.filter(y => y.CP === dato.CP && y.Colonia === dato.Colonia && y.entidad === dato.entidad && y.NumExt === dato.NumExt));
  
  
       
            me.map(z => z.map(y => { jmedico.push(y.Medico) }));
            let json = {
              CP: me[0][0].CP,
              Calle: me[0][0].Calle,
              Colonia: me[0][0].Colonia,
              Entidad: me[0][0].Entidad,
              Municipio: me[0][0].Municipio,
              NumExt: me[0][0].NumExt,
              Medicos: [jmedico],
              Clave:me[0][0].Clave
            }
          
            if (clin.length !== 0) {
              let ccli = clin.filter(y => y.CP === json.CP && y.Colonia === json.Colonia && y.entidad === json.Entidad && y.NumExt === json.NumExt);
              if (ccli.length === 0) {
                clin.push(json);
              }
            } else {
              clin.push(json);
            }
          })
        })
        setCurrentClinicas(clin)
      } else {
        setver(busqueda)
      
        setCurrentClinicas(clinics);
      }
    }
  }, [clinics.length]);

  let query = useQuery();
  let History = useHistory();

  useEffect(() => {
    setver(busqueda)

    if (Buscar !== '' && Buscar !== ' ') {


      setver(busqueda)
  
      if (ver) {

      } else {

        const busquedaPorBuscador = search(Buscar, clinics);
        setCurrentClinicas(busquedaPorBuscador);
        function search(Buscar, clinics) {
          if (Buscar !== '') {
            let arr = [];
            clinics.map((clinica) => {
              var nombreCompleto = [
                clinica.Medico.Nombre,
                clinica.Medico.ApellidoPaterno,
                clinica.Medico.ApellidoMaterno,
              ].join(' ');

              if (nombreCompleto.toLowerCase().includes(Buscar.toLowerCase())) {
                arr.push(clinica);
              }
            });
            return arr;
          }
        }
      }
    } else {

      setver(busqueda)
  
      if (ver) {
  
        var medic = [clinics];
        var medicd = [clinics];
        var clin = [];
        var jmedico = [];
        medic.map((inmedico) => {
          inmedico.Consultorios.map((dato) => {
            let me = medicd.map(x => x.Consultorios.filter(y => y.CP === dato.CP && y.Colonia === dato.Colonia && y.entidad === dato.entidad && y.NumExt === dato.NumExt));
  
  

            me.map(z => z.map(y => { jmedico.push(y.Medico) }));
            let json = {
              CP: me[0][0].CP,
              Calle: me[0][0].Calle,
              Colonia: me[0][0].Colonia,
              Entidad: me[0][0].Entidad,
              Municipio: me[0][0].Municipio,
              NumExt: me[0][0].NumExt,
              Medicos: [jmedico]
            }
      
            if (clin.length !== 0) {
              let ccli = clin.filter(y => y.CP === json.CP && y.Colonia === json.Colonia && y.entidad === json.Entidad && y.NumExt === json.NumExt);
              if (ccli.length === 0) {
                clin.push(json);
              }
            } else {
              clin.push(json);
            }
          })
        })
        setCurrentClinicas(clin)
      } else {

        setver(busqueda)

        setCurrentClinicas(clinics);
      }
    }
  }, [Buscar]);

  useEffect(() => {
    setver(busqueda)
    if (ver) {

    } else {
      if (currentClinicas) {
        currentClinicas.sort((a, b) => {
          let nameA = `${a.Medico.Nombre} ${a.Medico.ApellidoPaterno} ${a.Medico.ApellidoMaterno}`;
          let nameB = `${b.Medico.Nombre} ${b.Medico.ApellidoPaterno} ${b.Medico.ApellidoMaterno}`;
          var textA = nameA.toUpperCase();
          var textB = nameB.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        setCurrentClinicas(currentClinicas);
      }
    }
  }, [currentClinicas.length]);

  const setMunicipioFunc = (elemt) => {
    setMunicipio(elemt === 'Todos' ? { Nombre: 'Municipio' } : elemt);
  };

  const goToClinicsList = (municipio, entidad) => {
    setFetch(true);
    let params = {
      genero: 0,
      entidad: entidad.Clave,
      municipio: municipio.Clave ? municipio.Clave : 0,
      busquedas:busqueda
    };

    redGineApi.getConsultorios(params).then((response) => {
      setClinics(response.Consultorios);
      setFetch(false);
      setver(busqueda)

      History.push(
        `/directorio?entidad=${entidad.Nombre.toLowerCase()}&municipio=${municipio.Nombre === 'MUNICIPIO'
          ? 'todos'
          : municipio.Nombre.toLowerCase()
        }`
      );
    });
  };

  return (
    <div className='clinicas-container fade-in-slow'>
      <div className='red-gine-container'>
        <div className='fade-in-fast cli-container'>
          <div
            className={`dropDown-menu  ${municipio.Nombre === 'MUNICIPIO' ||
                municipio.Nombre === 'Municipio'
                ? 'hasNot_muni'
                : 'has_muni'
              }`}
          >
            <div className='dropdowns'>
              <DropDown
                id='id_buscar_entidad'
                category='filtro'
                type='estado'
                title={entidad.Nombre}
                array={Entidades}
                funcion={getMunicipio}
                isTodos={false}
              />
              <DropDown
                id='id_buscar_municipio'
                category='filtro'
                type='municipio'
                title={municipio.Nombre}
                array={municipios}
                funcion={setMunicipioFunc}
                disabled={!municipios ? true : false}
                tooltip='Selecciona un estado'
              />
              <button
                id='id_boton_buscar'
                onClick={() => goToClinicsList(municipio, entidad)}
                className='formCentral_button'
                disabled={entidad.Nombre !== 'ESTADO' ? false : true}
              >
                {fetch ? ' CARGANDO...' : ' BUSCAR'}
              </button>
            </div>
          </div>
          {clinics !== false ? (
            currentClinicas.length > 0 ? (
              <div className='listOfClinics-container  fade-in-fast'>
                <div className='left-container'>
                  <Map
                    entidad={query.get('entidad')}
                    municipio={query.get('municipio')}
                    dataToDisplay={currentClinicas}
                    iconPin={iconPin}
                   vtipo={ver}
                  />
                </div>
                <div className='right-container'>
                  {currentClinicas.map((clinica, index) => {
                    return <Clinica key={index} clinica={clinica} vtipo={ver} />;
                  })}
                </div>
              </div>
            ) : (
              <div className='start-clinics fade-in-slow'>
                <img src={Lupa} alt='Lupa' />
                <h1>!UPS! </h1>
                <br />
                <h2>
                  No hay registros que cumplan este filtro, <br /> puedes
                  realizar una nueva búsqueda
                </h2>

               
              </div>
            )
          ) : (
            <Spinner color='#fa8825' />
          )}
        </div>
      </div>
    </div>
  );
}

export default Contacto;
